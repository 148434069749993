import { Redirect, Route } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { VIEWS } from "../../../Constants";

const LoginRoutesComponent = (props) => {
  const { userData } = props;
  if (userData) return <Redirect to={"/task/checklist"} />;
  else return <Route {...props} />;
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(LoginRoutesComponent);
