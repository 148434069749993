import React, {useState} from "react";
import ListComponent from "../Components/ListComponent";
import Layout from "../../Common/Containers/_layouts/Home";
import Helmet from "react-helmet";

const List = () => {

    const [index, setIndex] = useState(0);

    const updateIndex = (index) => {
        setIndex(index);
    }

    return (
        <Layout updateIndex={updateIndex}>
            <Helmet>
                <title>{`Wobot | Checklist`}</title>
            </Helmet>
            <ListComponent index={index}/>
        </Layout>
    );
};

export default List;
