import React from "react";
import { connect, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import { toggleNavbarAction , changeActiveSidebarAction } from "../../../../Store/actions";
import { IconArrowBackLeft } from "../../../Components/IconsComponent/Index";
import { Scrollbars } from 'react-custom-scrollbars';
import "./process-sidebar.scss";
import {CHECKLIST, COMPLIANCE_RUN} from "../../../../Constants";
const Sidebar = (props) => {

    const isNavbarVisible = useSelector((state) => state.navbar);

    return (

        <nav id="sidebar-process-fixed" className={!isNavbarVisible ? "active" : ""}>
            <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%">
                <div className="sidebar-block">

                    <div id="sidebar-nav" className={!isNavbarVisible ? "active" : ""}>
                        <div className="sidebar-nav-link">

                            <div className="sidebar-top-block">
                                <Link to={props.backUrl ? props.backUrl : CHECKLIST} className="fs-16">
                                    <IconArrowBackLeft/> Back
                                </Link>
                            </div>
                            <div className="sidebar-content">
                                <div className="content-block">
                                    <h5>{props.checkList && props.checkList.model}</h5>
                                    <p> {props.checkList && props.checkList.brief}</p>
                                </div>

                                <ul className="content-link">
                                    {
                                        props.task && props.task.map((obj, index) => {
                                            return <li key={index} className={props.currentTaskIndex === index ? 'active' : ''}
                                                       onClick={() => {
                                                           if (!props.runChecklist) {
                                                               props.setSelectedTask(obj);
                                                               props.setCurrentTaskIndex(index);
                                                           }
                                                       }}>
                                                {
                                                    props.runChecklist ? <div className="checkbox-inline">
                                                        <input type="checkbox"
                                                               readOnly
                                                               checked={props.currentTaskIndex >= index}
                                                               className="check-with-label"/>
                                                        <label className="mb-0">{obj.model}</label>
                                                    </div> : <label>{
                                                        index !== 0 ? obj.model : "Introduction"
                                                    }</label>
                                                }
                                            </li>
                                        })
                                    }
                                </ul>
                                {/*<Link to={`${COMPLIANCE_RUN}${props.checkList.id}`}>*/}
                                {/*    <button className="btn btn-primary btn-md">Run Checklist</button>*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </nav>
    );
};
const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNavbarComponent: () => {
            dispatch(toggleNavbarAction());
        },
        changeActiveSidebar: (data) => {
            dispatch(changeActiveSidebarAction(data))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

