import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { toggleNavbarAction , changeActiveSidebarAction } from "../../../../Store/actions";
import { useLocation } from "react-router-dom";
// import "./style.scss";
import {API_BASE_URL_PUBLIC} from "../../../../Constants";
import { Scrollbars } from 'react-custom-scrollbars';
import callApi from "../../../../Services/callApi";
import "./aside-style.scss"

const Sidebar = (props) => {
    const location = useLocation();

    const [navBar, setNavBar] = useState('');

    const isNavbarVisible = useSelector((state) => state.navbar);

    const [industry, setIndustry] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState({});
    const [checklist, setChecklist] = useState([]);
    const userInfo = useSelector((state) => state);
    const [show, setShow] = useState(false);

    useEffect(() => {
        getIndustry();
    }, [])

    const getIndustry = async () => {
        const result = await callApi(`${API_BASE_URL_PUBLIC}/industry/get`);
        if (result && result.data && result.data.length) {
            let ind = [];
            for (let el of result.data) {
                ind.push({
                    value: el._id,
                    label: el.industry
                })
            }
            setIndustry(ind);
            if (ind && ind.length) {
                getCheckListByIndustry(ind[0]);
            }
        }
    }

    const getCheckListByIndustry = async (ind) => {
         const defaultIndustryId = ind;
        if (defaultIndustryId && defaultIndustryId.value) {
            setSelectedIndustry({...defaultIndustryId});
            const result = await callApi(`${API_BASE_URL_PUBLIC}/checklist/get/${defaultIndustryId && defaultIndustryId.value}`);
            if (result && result.data && result.data.checklists && result.data.checklists.length) {
                setChecklist([...result.data.checklists]);
             }
        }
    }

    return (
        <nav id="sidebar-fixed" className={!isNavbarVisible ? "active" : ""}>
            {/* <Scrollbars style={{ width: "100%", height: "100%" }}> */}
                                    <div id="sidebar-nav" className={!isNavbarVisible ? "active" : ""}>
                                        <div className="modal-sidebar">
                                            <div className="header-block">
                                                <p>Pre-made Templates</p>
                                                <h4>Browse</h4>
                                            </div>
                                            <div className="select-block">
                                                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%">
                                                <ul>
                                                    {
                                                        selectedIndustry &&
                                                        (industry || []).map((el, index) => {
                                                            return <li
                                                                className={selectedIndustry.value === el.value ? 'active' : ''}
                                                                id={el.value}
                                                                key={el.id}
                                                                onClick={
                                                                    () => {
                                                                        setSelectedIndustry(el);
                                                                        getCheckListByIndustry(el);
                                                                        props.updateIndex(index)
                                                                    }
                                                                }
                                                            >{el.label}</li>


                                                        })
                                                    }
                                                </ul>
                                                </Scrollbars>
                                                
                                            </div>
                                        </div>
                                    </div>
            {/* </Scrollbars> */}
        </nav>
    );
};
const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNavbarComponent: () => {
            dispatch(toggleNavbarAction());
        },
        changeActiveSidebar: (data) => {
            dispatch(changeActiveSidebarAction(data))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

