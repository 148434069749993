import { toast } from "react-toastify";

export const showAlert = (message, type = "info") => {
  toast(`${message}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: true,
    closeButton: true,
    type: `${type}`,
  });
};
