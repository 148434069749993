import {
  TOGGLE_NAVBAR,
  ADDDEVICEINFO,
  ADDUSERDATA,
  UPDATE_DETAILS,
  CHANGE_ACTIVE_SIDEBAR
} from "../constants";

export const toggleNavbarAction = () => {
  return {
    type: TOGGLE_NAVBAR,
    value: null,
  };
};
export const addUserDataToStoreAction = (data) => {
  console.log("data",data);
  return {
    type: ADDUSERDATA,
    value: data,
  };
};
export const addDeviceInfoToStoreAction = (data) => {
  return {
    type: ADDDEVICEINFO,
    value: data,
  };
};
export const updateCameraDetailsAction = (contact, index) => {
  return {
    type: UPDATE_DETAILS,
    contact,
    index,
  };
};
export const changeActiveSidebarAction = (data)=>{
  return {
    type : CHANGE_ACTIVE_SIDEBAR , 
    value  : data
  }
};
