export const API_BASE_URL = "https://api-app-prod.wobot.ai/app/v1";
export const API_BASE_URL_PUBLIC = "https://api-app-prod.wobot.ai/public/v1";

/**
 * Routes For Super-Admin-Dashboard
 * */

export const LOGIN = "/";

export const SIGNUP = "/signup";

export const FORGET = "/forget";

export const VERIFY = "/verify";

export const CHANGEPASSWORD = "/change-password";

export const SUCCESS = "/success";

export const ADDCOMPANY = "/add-company";

export const ADDTEAM = "/add-team";

export const VIEW = "/wocam/live";
export const CREATE_VIEW = "/wocam/live/create";

export const PLAYBACK = "/wocam/playback";

export const RECORDER = "/wocam/recorder";

export const CAMERA = "/wocam/camera";
export const CAMERA_DETAIL = "/wocam/camera/detail/:cameraId";
export const RECORDER_DETAIL = "/wocam/recorder/detail/:recorderId";

export const REGION = "/setting/region";

export const SUBREGION = "/setting/subregion";

export const LOCATION = "/setting/location";

export const TAT = "/setting/tat";

export const SCHEDULE = "/setting/schedule";

export const EMPLOYEE = "/setting/employee";
export const ADDEMPLOYEE = "/setting/employee/add";
export const EMPLOYEE_DETAIL = "/setting/employee/detail/:employeeId";

export const TASK = "/task/list";
export const TASK_DETAILS = "/task/detail";

export const TICKETING = "/task/ticketing";
export const TICKETING_DETAILS = "/task/ticketing/detail";

export const CHECKLIST = "/task/checklist";
export const CHECKLIST_DETAIL = "/task/checklist/:id";
export const CHECKLIST_INTRODUCTION = "/task/checklist/introduction";

export const COMPLIANCE_RUN = "/task/checklist/run/";
export const COMPLIANCE = "/task/checklist/run/:checklistId";
export const CONFIGURATION = "/task/checklist/compliance/configuration";
export const ADD_CAMERA = "/task/checklist/compliance/add-camera";
export const ADD_SCHEDULE = "/task/checklist/compliance/add-schedule";
export const ASSIGN_USER = "/task/checklist/compliance/assign-user";

const routes = [
    {
        enabled: true,
        path: VIEW,
        navbar: "Live",
        iconName: "IconLive",
        child: null,
    },
    {
        enabled: true,
        path: PLAYBACK,
        navbar: "Playback",
        iconName: "IconPlayback",
        child: null,
    },
    {
        enabled: true,
        path: RECORDER,
        navbar: "Recorder",
        iconName: "IconDVR",
        child: null,
    },
    {
        enabled: true,
        path: CAMERA,
        navbar: "Camera",
        iconName: "IconCamera",
        child: null,
    },
];

const routes_2 = [
    {
        enabled: true,
        path: EMPLOYEE,
        navbar: "Employees",
        iconName: "IconEmployee",
        child: null,
    },
    {
        enabled: true,
        path: REGION,
        navbar: "Region",
        iconName: "IconGlobe",
        child: null,
    },
    {
        enabled: true,
        path: SUBREGION,
        navbar: "Subregion",
        iconName: "IconMap",
        child: null,
    },
    {
        enabled: true,
        path: LOCATION,
        navbar: "Location",
        iconName: "IconLocation",
        child: null,
    },
    {
        enabled: true,
        path: SCHEDULE,
        navbar: "Schedule",
        iconName: "IconCalender",
        child: null,
    },
    {
        enabled: true,
        path: TAT,
        navbar: "TAT",
        iconName: "IconTAT",
        child: null,
    },


];

const routes_3 = [
    {
        enabled: true,
        path: CHECKLIST,
        navbar: "Checklist",
        iconName: "IconCheckbox",
        child: null,
    },
    {
        enabled: true,
        path: TICKETING,
        navbar: "Ticketing",
        iconName: "IconTicketing",
        child: null,
    },
    {
        enabled: true,
        path: TASK,
        navbar: "Task",
        iconName: "IconTask",
        child: null,
    },


];

export {routes, routes_2, routes_3};
