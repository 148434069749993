import React from 'react';
import {Link} from "react-router-dom";
import renderHTML from 'react-render-html';

const IntroductionComponent = (props) => {
    const openUrl = () => {
        let link = document.createElement('a')
        link.setAttribute('href', '//app.wobot.ai/signup/')
        link.setAttribute('target', '_blank')
        link.setAttribute('rel', 'noopener')
        link.click()
    }
    return (
        <div className="wobot-panel-main">
            <div className="main-body-wrapper">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 align-self-center mb-4">
                        <div className="title">
                            <h3 className="fs-22 header-primary">{
                                props.selectedTask && props.selectedTask.model
                            }</h3>
                        </div>
                        {
                            props.selectedTask.model !== 'Introduction' && <div className="block-group mt-4">
                            <Link to="#"
                                  onClick={() => openUrl()}
                                  className="btn btn-primary btn-md">
                                 Use Task
                            </Link>
                        </div>
                        }
                    </div>
                    <div className="col-12">
                        <div className="mxw-750">
                            {
                                props.selectedTask && props.selectedTask.description && renderHTML(props.selectedTask.description)
                            }
                        </div>
                        <div className="block-group mt-5">
                            <Link to="#" onClick={() => openUrl()}
                                  className="btn btn-primary btn-md">
                                {
                                    props.selectedTask.model === 'Introduction' ? 'Sign Up' : 'Use Task'
                                }
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default IntroductionComponent;
