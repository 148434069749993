import { routes } from "../../Constants"
import {
  TOGGLE_NAVBAR,
  ADDDEVICEINFO,
  ADDUSERDATA,
  UPDATE_DETAILS,
  CHANGE_ACTIVE_SIDEBAR
} from "../constants";

const initialState = {
  navbar: true ,
  userData : null ,
  deviceInfo : null , 
  activeNavBar:routes
}


const reducer = (state = initialState, action) => {
  
  switch (action.type) {
    case TOGGLE_NAVBAR:
      return { ...state, navbar: !state.navbar };
      case UPDATE_DETAILS:
        return {
          ...state,
          list: state.list.map((contact, index) =>
            index === action.index ? { ...action.contact } : contact
          ),
        };
        case ADDUSERDATA:
          return { ...state, userData: action.value };
        case ADDDEVICEINFO:
          return { ...state, deviceInfo: action.value };

        case CHANGE_ACTIVE_SIDEBAR  :
          console.log("action update sidebar :" , action);
          return {...state , activeNavBar : action.value};
    default:
      return state;
  }
};

export default reducer;
