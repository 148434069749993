import logout from "./logout";
import { getHeaders } from "./getHeaders";
import { store } from "../Store";

const callApi = (url, data) => {


  return new Promise((resolve, reject) => {
    fetch(url, { headers: getHeaders(), ...data })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) resolve(res);
        else if (res.status === 401) {
          logout();
          reject(res.message);
        } else reject(res.message);
      })
      .catch((e) => {
        reject(`ERROR OCCURRED : ${e}`);
      });
  });
};

export default callApi;
