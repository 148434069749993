import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import "./style.scss";
import {API_BASE_URL, API_BASE_URL_PUBLIC, CHECKLIST} from "../../../Constants";
import {useSelector} from "react-redux";
import callApi from "../../../Services/callApi";
import Select from 'react-select';
const HomeComponent = (props) => {
    const [industry, setIndustry] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState({});
    const [checklist, setChecklist] = useState([]);
    const userInfo = useSelector((state) => state);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        getIndustry();
    }, [props.index])

    const getIndustry = async () => {
        const result = await callApi(`${API_BASE_URL_PUBLIC}/industry/get`);
        if (result && result.data && result.data.length) {
            let ind = [];
            for (let el of result.data) {
                ind.push({
                    value: el._id,
                    label: el.industry
                })
            }
            setIndustry(ind);
            if (ind && ind.length) {
                getCheckListByIndustry(ind[props.index]);
            }
        }
    }

    const getCheckListByIndustry = async (ind) => {
         const defaultIndustryId = ind;
        if (defaultIndustryId && defaultIndustryId.value) {
            setSelectedIndustry({...defaultIndustryId});
            const result = await callApi(`${API_BASE_URL_PUBLIC}/checklist/get/${defaultIndustryId && defaultIndustryId.value}`);
            if (result && result.data && result.data.checklists) {
                setChecklist([...result.data.checklists]);
            }
        }
    }

    return (

                    <div className="col-md-12">
                        <div className="mobile-select">
                            <label>Browse by Industry</label>
                            <Select options={industry}
                                    onChange={(value) => {
                                        setSelectedIndustry(value);
                                        getCheckListByIndustry(value)
                                    }}
                            />
                        </div>
                        <div className="modal-checklist">
                            <div className="modal-container">
                                <div className="title">
                                    <h4>{selectedIndustry && selectedIndustry.label}</h4>
                                </div>
                                <div className="modal-main">

                                    {
                                        (checklist || []).map((el) => {
                                            return (

                                                <Link to={`${CHECKLIST}/${el._id}`} className="option-block">
                                                    <div className="main-block" key={el.id}>
                                                        <img
                                                            className="img-fluid"
                                                            src={el.icon ? el.icon : `/assets/images/checklist-placeholder.png`}
                                                            alt="img"
                                                        />
                                                        <div className="main-content">
                                                            <h5 className="title">{el.model}</h5>
                                                            <p className="mb-0">{el.brief}</p>
                                                        </div>
                                                    </div>
                                                </Link>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>


    );
};


export default HomeComponent;
