import { Redirect, Route } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { LOGIN } from "../../../Constants";

const PrivateRouteComponent = (props) => {
  return <Route {...props} />;
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(PrivateRouteComponent);
