import React from 'react';
import HomeComponent from "../HomeComponent";

const ListComponent = (props) => {
    return (
        <div className="wobot-panel-main">
            <div className="main-body-wrapper">
                <div className="row">
                    <HomeComponent index={props.index}/>
                </div>
            </div>
        </div>
    );
};


export default ListComponent;
