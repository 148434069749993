import React from "react";

const IconArrowBack = () => {
  return (

<svg width="16px" height="16px" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.0606 7.00006L8.53027 1.53039L7.46961 0.469727L0.939282 7.00006L7.46961 13.5304L8.53027 12.4697L3.0606 7.00006Z" fill="#3766E8"/>
</svg>

  );
};

export default IconArrowBack;
