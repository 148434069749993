import React, {useEffect, useState} from "react";
import IntroductionComponent from "../Components/IntroductionComponent";
import SidebarProcess from "../../Common/Containers/_layouts/ProcessSidebar";
import Header from "../../Common/Containers/_layouts/Header";
import {useSelector, connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {toggleNavbarAction} from "../../Store/actions";
import callApi from "../../Services/callApi";
import {API_BASE_URL, API_BASE_URL_PUBLIC} from "../../Constants";

const Introduction = (props) => {
    const isNavbarVisible = useSelector((state) => state.navbar);
    const {params} = props.match;
    const [task, setTask] = useState('');
    const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
    const [selectedTask, setSelectedTask] = useState([]);
    const [checkList, setCheckList] = useState([]);

    useEffect(() => {
        callApi(`${API_BASE_URL_PUBLIC}/checklist/tasks/get/${params.id}`).then((data) => {
            if (data && data.status === 200) {
                let taskData = {
                    model: 'Introduction',
                    description: data.data.checklist.description,
                };
                let arr = [taskData, ...data.data.tasks];
                setCheckList(data.data.checklist);
                setTask(arr)
                setSelectedTask(arr[0]);
            }
        })
    }, []);

    useEffect(() => {
        console.log(currentTaskIndex, "ddd");
    }, [currentTaskIndex]);

    return (
        <div className="wrapper">
            <SidebarProcess task={task} checkList={checkList} setSelectedTask={setSelectedTask}
                            setCurrentTaskIndex={setCurrentTaskIndex} currentTaskIndex={currentTaskIndex}/>

            <div id="content_process" className={!isNavbarVisible ? "active" : ""}>
                <Header/>
                <IntroductionComponent selectedTask={selectedTask}/>
            </div>
            <div
                className={!isNavbarVisible ? "overlay" : ""}
                onClick={() => {
                    props.toggleNavbarComponent();
                }}
            >
            </div>
        </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavbarComponent: () => {
      dispatch(toggleNavbarAction());
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Introduction));
