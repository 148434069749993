import React, {useCallback, useEffect} from "react";
import {BrowserRouter as Router, Switch, useHistory} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {toast} from "react-toastify";
import {deviceDetect, osName} from "react-device-detect";
import {showAlert} from "./Services/showAlert";
import {API_BASE_URL, CHECKLIST, CHECKLIST_DETAIL} from "./Constants";
import {addDeviceInfoToStoreAction, addUserDataToStoreAction,} from "./Store/actions";
import {connect} from "react-redux";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./Main.scss";

import {PrivateRouteComponent} from "./Common/Components/RouteHandlingComponents";

//Checklist
import List from "./Checklist/Containers/List";
import Introduction from "./Checklist/Containers/Introduction";


const App = (props) => {
    toast.configure();
    const history = useHistory();

    const createDeviceInfo = useCallback(() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const randomFourToFiveDigitNumber =
            Math.floor(Math.random() * (99999 - 1000 + 1)) + 1000;

        fetch(`${API_BASE_URL}`)
            .then((res) => {
                return res.json();
            })
            .then((el) => {
                const ip = el && el.data && el.data.ip ? el.data.ip : "";
                const deviceInfo = {
                    timeZone: timeZone,
                    deviceType: osName,
                    deviceId: `${ip}_${osName}_${randomFourToFiveDigitNumber}`.replace(
                        /\s/g,
                        ""
                    ),
                    name: `${deviceDetect().model ? deviceDetect().model : osName}`,
                    deviceIP: ip,
                };
                props.addDeviceInfo(deviceInfo);
            })
            .catch((e) => {
                showAlert(e);
                console.log(e);
            });
    }, [props]);

    useEffect(() => {
        if (!props.deviceInfo) createDeviceInfo();
    }, [props, createDeviceInfo]);

    return (
        <Router>
            <section className="wobot-dashboard">
                <Switch>
                    <PrivateRouteComponent exact path="/" component={history.push(CHECKLIST)}/>
                    <PrivateRouteComponent exact path={CHECKLIST} component={List}/>
                    <PrivateRouteComponent exact path={CHECKLIST_DETAIL} component={Introduction}/>
                </Switch>
            </section>
        </Router>
    );
};
const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = (dispatch) => {
    return {
        addDeviceInfo: (data) => {
            dispatch(addDeviceInfoToStoreAction(data));
        },
        addUserDataToStore: (data) => {
            dispatch(addUserDataToStoreAction(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

