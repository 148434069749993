import React from "react";
import {connect} from "react-redux";
import {toggleNavbarAction} from "../../../../Store/actions";
import "./style.scss";


export const HeaderComponent = (props) => {
    return (
        <header className="navbar header">
            <div
                id="sidebarCollapse"
                className="aside-toggler toggler pl-0"
                onClick={() => {
                    props.toggleNavbarComponent();
                }}
            >
                <span className="toggler-bar bg-primary"></span>
                <span className="toggler-bar bg-primary"></span>
                <span className="toggler-bar bg-primary"></span>
            </div>

            <img
                width="41"
                height="41"
                className="img-fluid mx-auto d-block"
                src={`/assets/images/logo-icon.png`}
                alt="logo"
            />


        <div className="opensans-font text-right userdropdown">
            <p type="text" className="fs-12 fw-400 mb-0 lh15">
                Welcome
                <span className="primary-color username fs-12 fw-500 d-block">
                    {" "}
                    {props &&
                    props.userData &&
                    props.userData.user &&
                    props.userData.user.name
                        ? props.userData.user.name
                        : "user"}
                    </span>
                </p>
            </div>
          <div className="ml-2 mr-1 user-thumb">
            <img
              width="30"
              height="30"
              className="img-fluid mx-auto d-block"
              src={`/assets/images/user_thumb.svg`}
              alt="logo"
            />
          </div>
    </header>
  );
};

const mapStateToProps = (state) => {
    return state;
};
const mapDispatchToProps = (dispatch) => {
    return {
        toggleNavbarComponent: () => {
            dispatch(toggleNavbarAction());
        },
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
